// external dependencies
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// gatsby dependencies
import { graphql, useStaticQuery } from 'gatsby';

// local dependencies
import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';
import BioCard from '../components/shared/BioCard';
import Section from '../components/shared/Section';
import Cta from '../components/shared/Cta';
import TwoColTextAndImage from '../components/shared/TwoColTextAndImage';
import EnhancedGrid from '../components/shared/EnhancedGrid';

// component definition
const WhoWeArePage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulWhoWeArePage {
        hero {
          buttonLink
          buttonText
          headline
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
            description
          }
        }
        metaDescription
        title
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
            description
          }
        }
        twoColumnTextAndImage {
          bodyCopy {
            bodyCopy
          }
          buttonLink
          buttonText
          reverseOrder
          supertext
          title
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
            description
          }
        }
      }
    }
  `);

  const twoColModules = data.page.twoColumnTextAndImage.map((item, i) => {
    return (
      <TwoColTextAndImage
        description={item.image.description}
        key={i}
        reverse={item.reverseOrder}
        image={item.image}
        superText={item.supertext}
        headline={item.title}
        body={item.bodyCopy.bodyCopy}
        altText={item.image.title}
      />
    );
  });

  return (
    <>
      <SEO
        description={data.page.metaDescription}
        title={data.page.hero.headline}
      />
      <Hero
        short
        headline={data.page.hero.headline}
        buttonColor='blue'
        supertext={data.page.hero.supertext}
        body={'Interested in joining our team? '}
        bodyTwo={'Please submit your resume to '}
        hrEmail={'hr@concretefence.com'}
      />
      <div style={{ background: '#f7f1f0' }}>{twoColModules}</div>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
        altText={data.page.ctaSection.image.description}
      />
    </>
  );
};

export default WhoWeArePage;
