// external dependencies
import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';

// gatsby dependencies
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

const StyledBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  min-width: 350px;
  height: 475px;
  position: relative;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  border-radius: ${(props) => props.theme.elements.borderRadius};
  margin: 5px auto;
`;

const StyledNameWrapper = styled.div`
  background-color: #282a73bf;
  min-height: 75px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  color: white;
  text-align: left;

  h5 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 0.8;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  min-height: 250px;
  margin-bottom: 1rem;

  @media (max-width: 950px) {
    min-height: 350px;
  }
`;

const StyledModal = styled.div`
  background: white;
  width: 65vw;

  max-height: 650px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  border-radius: ${(props) => props.theme.elements.borderRadius};
  padding: 50px;

  &:focus {
    outline: none;
  }

  @media (max-width: 650px) {
    width: 80vw;
  }

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

const StyledGridItem = styled((props) => <Grid {...props} />)`
  h5 {
    color: ${(props) => props.theme.colors.blue};
  }
  p#title {
    margin: 0;
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.gold};
  }

  p {
    margin: 0;
  }
`;

// component definition
const BioCard = ({ photo, name, title, bio }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "personnel_placeholder.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const placeholderImage =
    data.placeholderImage.childImageSharp.gatsbyImageData;

  const body = (
    <Grid container spacing={3}>
      <StyledGridItem item md={4} sm={12} xs={12}>
        <StyledImg
          image={photo ? photo.gatsbyImageData : placeholderImage}
          alt={name}
        />
        <h5>{name}</h5>
        <p id='title'>{title}</p>
      </StyledGridItem>
      <StyledGridItem item md={8} sm={12} xs={12}>
        <p>{bio}</p>
      </StyledGridItem>
    </Grid>
  );

  return (
    <>
      <Grid item onClick={handleOpen} style={{ cursor: 'pointer' }}>
        <div style={{ position: 'relative' }}>
          <StyledBackgroundImage
            loading='eager'
            image={photo ? photo.gatsbyImageData : placeholderImage}
            alt=''
          />
          <StyledNameWrapper>
            <div>
              <h5>{name}</h5>
              <p>{title}</p>
            </div>
          </StyledNameWrapper>
        </div>
      </Grid>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby='personnel-modal'
          aria-describedby='personnel-modal'
        >
          <StyledModal>
            <CancelIcon
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                cursor: 'pointer',
              }}
              htmlColor='#cdcdce'
              fontSize='large'
            />
            {body}
          </StyledModal>
        </Modal>
      )}
    </>
  );
};

export default BioCard;
